// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/theme2/backdrop-surat.webp");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main-bg[data-v-681cd054],.person-cover[data-v-681cd054],.surah-cover[data-v-681cd054]{background-repeat:no-repeat;background-size:cover;background-position:50%;-webkit-background-size:cover;-moz-background-size:cover;-o-background-size:cover}.surah-cover[data-v-681cd054]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:bottom!important}.person-cover[data-v-681cd054]{background-position:start!important;min-height:-moz-max-content + 100px;min-height:max-content + 100px;height:80vh;position:relative}.main-bg[data-v-681cd054]:after,.person-cover[data-v-681cd054]:after{content:\"\";background-image:linear-gradient(0deg,rgba(0,0,0,.4),rgba(0,0,0,.4));position:absolute;top:0;bottom:0;right:0;left:0}.scroll-element[data-v-681cd054]{width:inherit;height:inherit;position:absolute;left:0;top:0}.primary[data-v-681cd054]{animation:primary-681cd054 60s linear infinite}.secondary[data-v-681cd054]{animation:secondary-681cd054 60s linear infinite}@keyframes primary-681cd054{0%{transform:translateZ(0)}to{transform:translate3d(-1624px,0,0)}}@keyframes secondary-681cd054{0%{left:100%}to{left:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
