<template>
  <div class="relative select-none scroll-smooth overflow-x-hidden">
    <theme-one v-if="wedding.theme && wedding.theme.code === 'JRIS3X'" :wedding="wedding" />
    <theme-two v-else-if="wedding.theme && wedding.theme.code === 'N7790A'" :wedding="wedding" />
    <theme-one-nonis v-else-if="wedding.theme && wedding.theme.code === 'HXTBNI'" :wedding="wedding" />
    <div v-else class="h-screen grid place-items-center text-6xl text-gray-500 capitalize">no theme applied yet
      &#128554; </div>
  </div>
</template>

<script>
import 'moment/locale/id';
import themeOne from '~/components/themes/one.vue'
import themeOneNonis from '~/components/themes/oneNonis.vue'
import themeTwo from '~/components/themes/two.vue'

export default {
  name: 'InvitationPage',
  components: { themeOne, themeTwo, themeOneNonis },
  transition: "slide-bottom",
  async asyncData({ $axios, params, query, redirect }) {
    try {
      const data = await $axios.$get(`/api/v1/public/weddings/${params.wedding_code}?guest_code=${query.to}`)

      return { wedding: data.data, url: `https://bellioo.com/${params.wedding_code}` + (query.to && `?to=${query.to}`) }
    } catch (error) {
      redirect('/')
    }
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    return {
      htmlAttrs: {
        myAttribute: this.$i18n.locale,
        ...i18nHead.htmlAttrs
      },
      title: `${this.wedding.wife_nickname ?? this.wedding.wife} & ${this.wedding.husband_nickname ?? this.wedding.husband}`,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$t("makeSpecialDay")
        },
        {
          hid: 'keywords',
          name: 'description',
          content: this.$t("meta.description")
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: (`${this.wedding.wife_nickname ?? this.wedding.wife} & ${this.wedding.husband_nickname ?? this.wedding.husband}`) ?? this.$t("meta.title"),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.wedding.wedding_date ? this.$moment(this.wedding.wedding_date).format('dddd[,] D MMMM YYYY') : this.$t("meta.ogDescription")
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: 'Bellioo',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content:
            this.wedding.images[0].url ?? 'https://bellioo.com/logo.png',
        },
        {
          hid: 'og:url',
          name: 'og:url',
          content: this.url ?? 'https://bellioo.com',
        },
        {
          hid: 'og:type',
          name: 'og:type',
          content: `website`,
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          hid: 'twitter:site',
          name: 'twitter:site',
          content: this.url ?? 'https://bellioo.com',
        },
        {
          hid: 'twitter:creator',
          name: 'twitter:creator',
          content: 'bellioo',
        },
        {
          hid: 'twitter:url',
          name: 'twitter:url',
          content: this.url ?? 'https://bellioo.com',
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: this.$t("weddingInvitation") + ` ${this.wedding.wife_nickname ?? this.wedding.wife} & ${this.wedding.husband_nickname ?? this.wedding.husband}`,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: this.wedding.wedding_date ? this.$moment(this.wedding.wedding_date).format('dddd[,] D MMMM YYYY') : this.$t("meta.ogDescription")
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content:
            this.wedding.images[0].url ?? 'https://bellioo.com/logo.png',
        },
        ...i18nHead.meta
      ],
      link: [
        ...i18nHead.link
      ]
    };
  },
}
</script>
